// Sylius colors.
$color-sylius: #1abb9c;
$color-sylius-dark: darken($color-sylius, 5%);
$color-sylius-light: lighten($color-sylius, 5%);

// Background colors.
$color-background: #f9fAfb;
$color-header-background: #f9fAfb;

// Links.
$color-link: rgba(0,0,0,.87);
