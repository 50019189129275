body {
  background-color: #F9FAFB;
}

#menu {
  border-radius: 0;
}

#footer {
  padding: 4em;
  margin-top: 4em;
}

.pusher {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.pusher > #footer {
  flex: 1;
}

.cart.popup {
  min-width: 300px;
}

.ui.monster.header {
  font-size: 3em;
}
.ui.unmargined.segments {
  margin: 0;
}
